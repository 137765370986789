import React from "react"
import Layout from "../components/Layout"

const Contact = () => {
  return (
    <Layout>
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Top Website einen Klick entfernt</h3>
            <p>
              Ihr Unternehmen ist eigentlich gut aufgestellt, aber Sie haben das
              Gefühl "aus den Daten müssen wir mehr rausholen"? Ihre Webseiten
              sind veraltet und irgendwie haben Sie das Gefühl den Anschluss zu
              verpasst? Sie haben die Idee durch Webapps ihre Kundenbindung
              auszubauen und zu verstärken? Dann sind Sie bei Sweet Data
              Development richtig.
            </p>
            <p>Nehmen Sie sich die Zeit und schildern sie uns ihr Vorhaben.</p>
            <p>
              Sweet Data Development ist keine gewöhnliche Webentwicklungsbude.
              Wir streben nach einer kundenspezifische End-to-End-Weblösung mit
              besonderen Fokus auf der Analyse ihrer Prozessdaten. Mehrwert
              schaffen durch Interaktion mit dem Kunden.
            </p>
          </article>
          <article>
            <form className="form contact-form">
              <div className="form-row">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="form-row">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="message">Nachricht</label>
                <textarea name="message" id="message"></textarea>
              </div>
              <button type="submit" className="btn block">
                Absenden
              </button>
            </form>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Contact
